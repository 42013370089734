import { AccessibilityIcon, BedIcon, CupIcon, DumbbellIcon, MoneyBagIcon, VideotapeIcon } from '@hermes/web-components'
import { PracticeFacility, FacilityItem } from '../types'
import { camelCase } from './strings'

const facilitiesIcons = {
  general: BedIcon,
  paymentOptions: MoneyBagIcon,
  accessibility: AccessibilityIcon,
  leisureRecovery: VideotapeIcon,
  dining: CupIcon,
  wellbeingRehabilitation: DumbbellIcon
}

const extractFacilitiesTypes = (facilities: PracticeFacility[], locale: string) => {
  const result: Record<string, FacilityItem> = {}

  facilities?.forEach((item) => {
    const subtype = item.Facility.data.subtype
    const subtypeKey = camelCase(subtype?.key)
    const type = item.Facility.type
    const name = item.Facility.name

    const subtypeName = subtype?.name[locale] || type

    if (!result[subtypeName]) {
      result[subtypeName] = {
        name: subtype?.name,
        type,
        Icon: facilitiesIcons?.[subtypeKey as keyof typeof facilitiesIcons] ?? null,
        items: []
      }
    }

    result[subtypeName].items.push({ name, value: item.value || {}, pinned: item.pinned })
  })

  const resultValues = Object.values(result)

  return {
    facilities: resultValues?.filter((facility) => facility.type === 'Facilities') || [],
    services: resultValues?.filter((facility) => facility.type === 'Services') || [],
    parking: resultValues?.filter((facility) => facility.type === 'Parking') || [],
    pinned: facilities?.filter((facility) => facility.pinned) || []
  }
}

export default extractFacilitiesTypes
