import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

const BedIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="path-1-inside-1_10490_3983" fill="white">
      <rect x="5.45508" y="5.2959" width="13.0909" height="5.93939" rx="1" />
    </mask>
    <rect
      x="5.45508"
      y="5.2959"
      width="13.0909"
      height="5.93939"
      rx="1"
      stroke="#051851"
      strokeWidth="2.4"
      fill="none"
      mask="url(#path-1-inside-1_10490_3983)"
    />
    <path
      d="M3.4 19.2959C3.4 19.6273 3.66863 19.8959 4 19.8959C4.33137 19.8959 4.6 19.6273 4.6 19.2959H3.4ZM4.13789 13.6169L3.62062 13.3129L4.13789 13.6169ZM5.76845 9.65856L3.62062 13.3129L4.65515 13.9209L6.80298 10.2666L5.76845 9.65856ZM3.4 14.1236V19.2959H4.6V14.1236H3.4ZM3.62062 13.3129C3.47617 13.5586 3.4 13.8385 3.4 14.1236H4.6C4.6 14.0523 4.61904 13.9823 4.65515 13.9209L3.62062 13.3129Z"
      fill="#051851"
    />
    <path
      d="M19.4006 19.2959C19.4006 19.6273 19.6692 19.8959 20.0006 19.8959C20.3319 19.8959 20.6006 19.6273 20.6006 19.2959H19.4006ZM19.8718 13.7921L19.3491 14.0865L19.8718 13.7921ZM17.1921 10.257L19.3491 14.0865L20.3946 13.4976L18.2376 9.66812L17.1921 10.257ZM19.4006 14.2828V19.2959H20.6006V14.2828H19.4006ZM19.3491 14.0865C19.3828 14.1464 19.4006 14.214 19.4006 14.2828H20.6006C20.6006 14.0077 20.5296 13.7373 20.3946 13.4976L19.3491 14.0865Z"
      fill="#051851"
    />
    <path
      d="M8.36328 10.4929V8.76563C8.36328 8.48948 8.58714 8.26562 8.86328 8.26562H11.9996M15.636 10.4929V8.76562C15.636 8.48948 15.4122 8.26562 15.136 8.26562H11.9996M11.9996 8.26562V10.4929"
      stroke="#051851"
      strokeWidth="1.2"
    />
    <path d="M4 14.6292H20M4 18.1292H20" stroke="#051851" strokeWidth="1.2" />
  </SvgIcon>
)

export default BedIcon
