import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

const AccessibilityIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.7634 10.8047C9.05708 10.7925 8.35951 10.9623 7.73788 11.2979C7.11624 11.6335 6.59149 12.1235 6.21417 12.7207C5.83684 13.3179 5.61965 14.0023 5.58351 14.7078C5.54738 15.4133 5.69351 16.1162 6.00784 16.7488C6.32218 17.3815 6.79411 17.9225 7.37821 18.3199C7.9623 18.7172 8.63886 18.9575 9.34274 19.0175C10.0466 19.0775 10.7541 18.9552 11.397 18.6625C12.0399 18.3698 12.5967 17.9165 13.0136 17.3462M14.7004 6.69057C14.7004 7.12703 14.8737 7.5456 15.1824 7.85422C15.491 8.16284 15.9096 8.33622 16.346 8.33622C16.7825 8.33622 17.201 8.16284 17.5097 7.85422C17.8183 7.5456 17.9917 7.12703 17.9917 6.69057C17.9917 6.25412 17.8183 5.83554 17.5097 5.52692C17.201 5.2183 16.7825 5.04492 16.346 5.04492C15.9096 5.04492 15.491 5.2183 15.1824 5.52692C14.8737 5.83554 14.7004 6.25412 14.7004 6.69057Z"
      stroke="#051851"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M17.9917 18.2099L14.7004 14.0957H11.409L13.8775 9.9816L10.5862 7.51312L7.29492 8.33595"
      stroke="#051851"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
)

export default AccessibilityIcon
