import React from 'react'
import { SvgIcon, SvgIconProps } from '../../components/SvgIcon'

const MoneyBagIcon = ({ ...props }: SvgIconProps) => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.6411 21.0469H10.1463C8.5309 21.0469 7.06083 20.114 6.373 18.6523V18.6523C5.76813 17.367 5.85917 15.8621 6.61458 14.6591L10.1217 9.07364L8.59229 4.48535H15.4092L13.7487 9.07364L17.285 14.5534C18.0985 15.814 18.1798 17.4122 17.4984 18.7488V18.7488C16.7795 20.159 15.3303 21.0469 13.7473 21.0469H12.4573"
      stroke="#051851"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <rect x="8.67969" y="7.67578" width="6.64209" height="2.35969" rx="1.17984" fill="#051851" />
    <path
      d="M13.9073 17.4066C14.2164 17.5156 14.2182 17.9559 13.9073 18.0596H10.4913C10.2601 18.0596 10.1611 17.766 10.3437 17.6243C10.5503 17.4136 10.7028 17.2054 10.8011 16.9994C10.8995 16.7888 10.9487 16.529 10.9487 16.2201C10.9487 16.1265 10.9364 16.0282 10.9118 15.9252H10.1002C9.82281 15.8444 9.82281 15.4514 10.1002 15.3705H10.7274C10.6241 15.0757 10.5724 14.7901 10.5724 14.514C10.5724 14.2004 10.6437 13.9289 10.7864 13.6995C10.929 13.4702 11.1258 13.2947 11.3766 13.173C11.6275 13.0466 11.9177 12.9834 12.2473 12.9834C12.7736 12.9834 13.172 13.1238 13.4425 13.4047C13.7131 13.6855 13.8532 14.0436 13.8631 14.4789C13.7702 14.8334 13.2854 14.8788 13.1284 14.5476L13.0958 14.4789C13.0958 14.2261 13.0269 14.0272 12.8892 13.8821C12.7514 13.7323 12.5375 13.6574 12.2473 13.6574C11.9817 13.6574 11.7677 13.73 11.6054 13.8751C11.448 14.0202 11.3693 14.2472 11.3693 14.5561C11.3693 14.6918 11.384 14.8229 11.4135 14.9493C11.443 15.0757 11.4849 15.2161 11.539 15.3705H12.9703C13.2673 15.4362 13.2673 15.8595 12.9703 15.9252H11.7087C11.7333 16.0328 11.7455 16.1335 11.7455 16.2271C11.7455 16.6811 11.5808 17.0743 11.2512 17.4066H13.9073Z"
      fill="#051851"
    />
  </SvgIcon>
)

export default MoneyBagIcon
